import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Parse from 'html-react-parser'
import TitlePage from '../common/template/titlePage'

import { Base_url, BaseUrlImg } from '../common/const'
import { limpaTag } from '../common/util'



export default class sobre extends Component {

    constructor(props) {
        super();
        this.state = { sobreEndPoint: [], sobreEndPointTexto: '', descricaoEndPointTexto: '', descricaoEndPoint: [], textoDescricaoSecundaria: '' }
    }


    componentWillMount() {

        axios.all([
            axios.get(`${Base_url}/institucional/6?grupo=sobre`),
            axios.get(`${Base_url}/institucional/7?grupo=sobre`),
            axios.get(`${Base_url}/institucional/9?grupo=sobre`)
        ])
            .then(axios.spread((sobRes, desRes, descSecRes) => {
                this.setState({ sobreEndPoint: sobRes.data.data, sobreEndPointTexto: sobRes.data.data.texto, descricaoEndPointTexto: desRes.data.data.texto, descricaoEndPoint: desRes.data.data, textoDescricaoSecundaria: descSecRes.data.data.texto })
            }));

    }


    render() {
        const { sobreEndPoint, sobreEndPointTexto, descricaoEndPointTexto, descricaoEndPoint, textoDescricaoSecundaria } = this.state
        const textSec = limpaTag(textoDescricaoSecundaria).split(",")
        var Background = BaseUrlImg + '/' + descricaoEndPoint.imagem_name;


        return (
            <div className="sobre">
                <div className="container-fluid internas">
                    <TitlePage titulo='Sobre' descricao="Projeto Engenheiros do Futuro">
                        <div className="col-sm-3 col-md-6">
                            <img className="float-right" src="./assets/images/logo_sobre.png" width='80' alt="Logo" />
                        </div>
                    </TitlePage>
                    <hr />
                </div>
                <div className="container-fluid mt-5 banner-sobre "></div>


                <div class="container-fluid internas">
                    <div class="row">
                        <div class="col-md-6 desc-esq">
                            <h1>{sobreEndPoint.titulo}</h1>
                            <p>{Parse(sobreEndPointTexto)}</p>
                        </div>
                        <div class="col-md-6 detalhes">

                            {textSec.map((sec, keySec) =>
                                <div className='descricao-bloco' key={keySec}>
                                    <img src="./assets/images/ellipse.png" alt="" />
                                    <p className='pergunta'>{sec}</p>
                                </div>
                            )}

                        </div>
                    </div>
                </div>

                <div class="container-fluid internas mt-0">
                    <div class="row align-items-center mt-0">

                        <div class="col-md-6 ric-desc">
                            <p>{Parse(descricaoEndPointTexto)}</p>
                            <div className='clearfix'></div>
                            <Link to='/ricardo'><div className='w-100 d-flex justify-content-center'><button>RICARDO LOMBARDI</button></div></Link>
                        </div>

                        <div class="col-md-6 ric-img" style={{ backgroundImage: `url(${Background})` }}></div>
                    </div>
                    <div class="row justify-content-center ">

                        <div class="col-md-3 insc">
                            <Link to='/login'><button>INSCREVA-SE</button></Link>
                        </div>

                    </div>
                </div>




            </div>

        )
    }
}
