import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import axios from 'axios'

import {Base_url} from '../common/const'

import TitlePage from '../common/template/titlePage'
import Galeria from '../common/galeria/galeria';



export default class fotos extends Component {


    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleCurso = this.handleCurso.bind(this);
        this.state = {day: undefined, curso:'', galeriaEndPoint:[]};
      }

      componentWillMount(){
         
          axios.all([
            // axios.get(`${Base_url}/galerias-principal`),
            axios.get(`${Base_url}/galerias`)
          ])
          .then(axios.spread(( galRes) => {
             
                this.setState({ galeriaEndPoint: galRes.data.data })
          }));
         
    }
    
      handleDayChange(selectedDay) {
          const data = formatDate(selectedDay,'YYYY-MM-DD', 'pt-br' );
          this.setState({day: data});
      }

      handleCurso(event) {
        this.setState({curso: event.target.value});
      }
    
    render() {

        const { day, curso, galeriaEndPoint } = this.state;
        return (
            <div className="fotos">
                <div className="container-fluid internas">
                    <TitlePage titulo='Fotos' descricao="Confira fotos de alguns momentos de nossos cursos">
                        <div className="col-md-6">
                            <form>
                                <div className="form-row">
                                    {/* <div className="col-md-4">
                                         <DayPickerInput
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            onDayChange={this.handleDayChange}
                                            format='DD/MM/YYYY'
                                            placeholder={`${formatDate(new Date(),'DD/MM/YYYY', 'pt-br')}`}
                                            dayPickerProps={{
                                            locale: 'pt-br',
                                            localeUtils: MomentLocaleUtils,
                                            }}
                                        /> 
                                    </div> */}
                                    
                                    {/* <div className="col-md-4 col-cursos">
                                        <select  onChange={this.handleCurso} value={curso}>
                                            <option value="0">TODOS</option>
                                            <option value="1">CURSO 1</option>
                                            <option value="2">CURSO 2</option>
                                            <option value="3">CURSO 3</option>
                                        </select>
                                    </div> */}
                                </div>
                            </form>
                            {/* {day}
                            {curso} */}
                        </div>
                    </TitlePage>
                    <hr/>
                </div>
                <div className="container-fluid galeria">
                    <Galeria request={galeriaEndPoint}/>
                </div>
            </div>
        )
    }
}
