import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Base_url, BaseUrlImg } from '../common/const'
import Parse from 'html-react-parser';
import { BaseUrlAssinaturas, BaseToken } from '../common/const'
import { connect } from 'react-redux'
import { menu } from '../login/authActions'
import { bindActionCreators } from 'redux'
import If from '../common/operator/if'



class minhasassinaturas extends Component {

    constructor() {
        super()
        this.state = { assinaturas: [], vazio:true }

    }

    componentWillMount() {

        const config = {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('_engenheiro_token')
            }
        }


        axios.get(`${BaseToken}/assinantes/${this.props.user.assinante.id}/assinaturas`, config)
            .then(response => {
               this.setState({ assinaturas: response.data.data , vazio:false})
            })
            .catch(e => console.log(e))

        this.props.menu(true)


    }


    render() {
        const { assinaturas, vazio } = this.state
        if (!this.props.user) {
            this.props.history.push("/login")
        }

        return (

            <div>
                <div className="container-fluid barra-assinaturas p-0">

                    <div className="row titulo-pagina align-items-center m-0">
                        <div className="col-md-6 descricao">
                            <h2>MINHAS ASSINATURAS</h2>
                        </div>
                    </div>

                </div>
                <div className="container-fluid internas assinaturas">
                    <div className="row">

                        <If condition={assinaturas}>

                            {assinaturas.map((curso, index) =>

                                <div className='col-md-4' key={index}>
                                    <Link to={`/player/${curso.id}`}>
                                        <div className=" cursos" key="index">
                                            <img src={BaseUrlAssinaturas + '/' + curso.imagens[0].name} alt="" />

                                            <p>{Parse(curso.texto)}</p>
                                            <button className="button_curso">ACESSAR</button>

                                        </div>
                                    </Link>
                                </div>
                            )}
                        </If>
                        <If condition={vazio}>
                            <p>Você ainda tem não tem cursos disponíveis, acesse as <span className='link-assinatura'><Link to={`assinaturas`}>assinaturas</Link></span> disponíveis.</p>
                        </If>

                    </div>
                </div>
            </div>


        )
    }
}

const mapStateToProps = state => ({ user: state.auth.user })
const mapDispatchToProps = dispatch => bindActionCreators({ menu }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(minhasassinaturas)