const userKey = '_engenheiro_user'
const userToken = '_engenheiro_token'
const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem(userKey)),
  validToken: false,
  textErro: '',
  showMenu: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MENU_VALIDATED':
      
        return { ...state, showMenu: action.payload }

    case 'ERROR_LOGIN':
      if (action.payload) {
        return { ...state, textErro: action.payload }
      }

  
    case 'TOKEN_VALIDATED':
      if (action.payload) {
        return { ...state, validToken: true }
      } else {
        localStorage.removeItem(userKey)
        localStorage.removeItem(userToken)
        return { ...state, validToken: false, user: null }
      }

    case 'USER_FETCHED':
      localStorage.setItem(userKey, JSON.stringify(action.payload))
      return { ...state, user: action.payload, validToken: true }

    default:
      return state
  }
}