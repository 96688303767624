import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class favoritos extends Component {
    render() {
        return (
            <Link  to={`artigo/${this.props.id}/${this.props.slug}`}>
                <div className="favorito">
                    <img src={this.props.imagem} className="img-fluid" alt="" width='200'/>
                    <div className="info">
                        <h5>{this.props.titulo}</h5>
                        <p>{this.props.descricao}</p>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Link>
        )
    }
}
