// import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import { BaseUrlPostAssinantes, urlApi } from '../common/const'

const userToken = '_engenheiro_token'

export function login(values) {
    return submit(values, `${BaseUrlPostAssinantes}/auth`)
}


function submit(values, url) {
    const dados = {"email":values.email, "password": values.password}

    return dispatch => {
        axios.post(url, values)
            .then(resp => {
                
                dispatch([
                    { type: 'USER_FETCHED', payload: resp.data.data.user }
                ])

                axios.post(`${urlApi}/authentication`, dados )
                .then(resp => {
                    localStorage.setItem(userToken, resp.data.data.access_token)
                  
                })
                .catch(e => console.log(e))

            })
            .catch(e => {
                dispatch([
                    { type: 'ERROR_LOGIN', payload: e.response.data.fails[0] }
                ])
            })

           
    }

    
        

   
}

export function logout() {
    return { type: 'TOKEN_VALIDATED', payload: false }
}

export function menu(value) {

    return dispatch => {

        dispatch([
            { type: 'MENU_VALIDATED', payload: value }
        ])

    }

}

