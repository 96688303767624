import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BaseUrlPostAssinantes } from '../common/const'
import If from '../common/operator/if'
import InputMask from "react-input-mask";
import { menu } from '../login/authActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


class cadastro extends Component {

    constructor() {
        super()
        this.buscaCep = this.buscaCep.bind(this)
        this.state = { nome: '', cpf: '', email: '', telefone: '', cep: '', uf: '', cidade: '', logradouro: '', bairro: '', password: '', statusRequest: 0, error: [] }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentWillMount() {
        this.props.menu(false)
    }


    buscaCep(event) {
        this.setState({ cep: event.target.value })
        axios(`https://viacep.com.br/ws/${event.target.value}/json/`, { method: 'GET' })
            .then(response => {

                this.setState({
                    cidade: response.data.localidade,
                    uf: response.data.uf,
                    bairro: response.data.bairro,
                    logradouro: response.data.logradouro,
                })
            })

    }

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({
            ...this.state,
            [evt.target.name]: value
        });
    }

    handleSubmit = event => {
        event.preventDefault();


        fetch(BaseUrlPostAssinantes + '/auth/new', {
            method: 'post',
            body: JSON.stringify({ ...this.state })
        }).then(response => response.json())
            .then(json => { this.setState({ error: json.fails ? json.fails : [], statusRequest: json.status }) })
            .catch(erro => { this.setState({ statusRequest: 500 }) })

    }



    render() {

        const { nome, cpf, email, telefone, cep, uf, cidade, logradouro, bairro, password, statusRequest, error } = this.state
        return (
            <div className="login container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Cadastre-se</h1>
                        <h3>Todos os dados abaixo são obrigatórios!</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <hr className="mt-5" />
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-12">


                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <input type="text" className={`form-control ${this.state.error.nome ? 'border-red' : ''}`} name='nome' aria-describedby="emailHelp" placeholder='Nome Completo' value={nome} onChange={(e) => this.handleChange(e)} />
                                <small id="emailHelp" className="form-text text-muted ">{this.state.error.nome}</small>
                            </div>
                            <div className="form-group">
                                <InputMask mask="999.999.999-99" className={`form-control ${this.state.error.cpf ? 'border-red' : ''}`} maskPlaceholder="-" value={cpf} placeholder='Cpf' onChange={(e) => this.handleChange(e)} name='cpf' />
                                <small id="emailHelp" className="form-text text-muted">{this.state.error.cpf}</small>
                            </div>
                            <div className="form-group">
                                <input type="email" className={`form-control ${this.state.error.email ? 'border-red' : ''}`} name='email' aria-describedby="emailHelp" placeholder='Email' value={email} onChange={(e) => this.handleChange(e)} />
                                <small id="emailHelp" className="form-text text-muted">{this.state.error.email}</small>
                            </div>
                            <div className="form-group">
                                <input type="text" className={`form-control ${this.state.error.telefone ? 'border-red' : ''}`} name='telefone' aria-describedby="emailHelp" placeholder='Telefone' value={telefone} onChange={(e) => this.handleChange(e)} />
                                <small id="emailHelp" className="form-text text-muted">{this.state.error.telefone}</small>
                            </div>
                            <div className="form-group">
                                <InputMask mask="99999-999" className="form-control" name='cep' placeholder='CEP' onChange={(e) => this.buscaCep(e)} value={cep} />
                            </div>

                            <div class="form-group">

                                <select class="form-control" name='uf' value={uf} onChange={(e) => this.handleChange(e)}>
                                    <option value="  ">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                    <option value="EX">Estrangeiro</option>
                                </select>
                            </div>


                            <div className="form-group">
                                <input type="text" className="form-control" name='cidade' aria-describedby="emailHelp" placeholder='Cidade' value={cidade} onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name='bairro' aria-describedby="emailHelp" placeholder='Bairro' value={bairro} onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name='logradouro' aria-describedby="emailHelp" placeholder='Endereço' value={logradouro} onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Senha</label>
                                <input type="password" className={`form-control ${this.state.error.password ? 'border-red' : ''}`} name='password' aria-describedby="emailHelp" value={password} onChange={(e) => this.handleChange(e)} />
                                <small id="emailHelp" className="form-text text-muted">{this.state.error.password}</small>
                            </div>
                            <div className="form-group">
                                <button type="submit" className=" entrar">Cadastrar</button>
                                <Link to='/login'><button type="submit" className=" cadastro">Voltar</button></Link>

                                <If condition={statusRequest === 200}>
                                    <div class="alert alert-success mt-3" role="alert">
                                        Cadastro realizado com sucesso, retorne para o login e acesse sua conta!
                                    </div>
                                </If>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ menu }, dispatch)
export default connect(null, mapDispatchToProps)(cadastro)