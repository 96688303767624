import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import If from '../../common/operator/if'
import { logout, menu } from '../../login/authActions'
import { bindActionCreators } from 'redux'


class navBar extends Component {


    render() {

        return (
            <If condition={this.props.showMenu}>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <button><Link to='/'><img src="/assets/images/logo-engenheiros.png" alt="" className='logo-img' /></Link></button>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Alterna navegação">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li>
                                <Link to='/assinaturas'><button>Engenharia</button></Link>
                            </li>
                            <li>
                                <Link to='/conteudo'><button>Conteúdo</button></Link>
                            </li>
                            <li>
                                <Link to='/videos'><button>Vídeos</button></Link>
                            </li>
                            <li>
                                <Link to='/fotos'><button>Fotos</button></Link>
                            </li>
                            <li>
                                <Link to='/sobre'><button>Sobre</button></Link>
                            </li>
                            <If condition={!this.props.user}>
                                <li className='btn-login-menu'>
                                    <Link to='/login'><button className="btn-login" ><span>ÁREA DO ASSINANTE</span></button></Link>
                                </li>
                            </If>
                        </ul>
                    </div>
                    <div className='clearfix'></div>
                    <If condition={!this.props.user}>
                        <Link to='/login'><button className="btn-login" >ÁREA DO ASSINANTE</button></Link>
                    </If>

                    <If condition={this.props.user}>
                        <div className="collapseButtonLogin">
                            <div data-toggle="collapse" href="#collapseLogin" role="button" aria-expanded="false" aria-controls="collapseLogin">
                                <img src="../assets/images/avatar.png" alt="" className="avatar_login" width='67' />
                                <span className='nome-login'>{this.props.user ?
                                    this.props.user.name.split(" ")[0]
                                    : ''}
                                </span>

                                <div className="collapse" id="collapseLogin">
                                    <ul className="navbar-nav">
                                        <li>
                                            <Link to='/minhasassinaturas'><button>Assinaturas</button></Link>
                                            <hr />
                                        </li>
                                        <li>
                                            <Link to='/perfil'><button>Meu Perfil</button></Link>
                                            <hr />
                                        </li>
                                        <li>
                                            <button onClick={this.props.logout} >Sair</button>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        

                    </If>

                </nav>
            </If>

        )
    }
}



const mapStateToProps = state => ({ user: state.auth.user, showMenu: state.auth.showMenu })
const mapDispatchToProps = dispatch => bindActionCreators({ logout, menu }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(navBar)
