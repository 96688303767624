import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Base_url, BaseUrlImg, BaseUrlAssinaturas,BaseUrlVideos} from '../common/const'
import 'video-react/dist/video-react.css'; 
import axios from 'axios'

import { Player, 
        BigPlayButton,
        ControlBar,
        ReplayControl,
        ForwardControl,
        CurrentTimeDisplay,
        TimeDivider,
        PlaybackRateMenuButton,
        VolumeMenuButton 
    } from 'video-react';


export default class player extends Component {

    constructor(){
        super()
        this.state = { videos:[], titulo:'', videoAtivo:'' }
        this.urlChange = this.urlChange.bind(this)
       
    }

    componentWillMount(){
        axios.all([
            axios.get(`${Base_url}/assinaturas/${this.props.match.params.id}`)
          ])
          .then(axios.spread((assRes) => {
            this.setState({ 
                videos: assRes.data.data.videos,
                videoAtivo: assRes.data.data.videos[0].name,
                titulo: assRes.data.data.titulo,
            })
          }));
    }

    urlChange(url) {
        this.setState({videoAtivo: url });
        this.player.load();
    }


    render() {
        const { videos, titulo,videoAtivo } = this.state 

        return (

            <div>
                <div className="container-fluid barra-assinaturas p-0">
                    <div className="row titulo-pagina align-items-center m-0">
                        <div className="col-md-6 descricao">
                            <h2>{`CURSO - ${titulo}`}</h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid internas assinaturas">
                    <div className="row player">
                
                        <div class="col-md-9">
                            <Player src={BaseUrlAssinaturas+'/'+videoAtivo}
                                ref={player => {this.player = player; }} 
                                fluid={false} 
                                height={600} 
                                width={'100%'}
                                preload={'auto'}
                                >
                                <BigPlayButton position="center" />

                                <ControlBar>
                                    <ReplayControl seconds={10} order={1.1} />
                                    <ForwardControl seconds={10} order={1.2} />
                                    <CurrentTimeDisplay order={4.1} />
                                    <TimeDivider order={4.2} />
                                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                    <VolumeMenuButton />
                                </ControlBar>
                            </Player>
                            <p className='descricao-video'></p>
                            <a className='mt-3' href="#">BAIXAR MATERIAL</a>
                        </div>
                        <div class="col-md-3 lista">
                                {videos.map((video, index) =>
                                    <div className="col-md-12 itens" key={index}>
                                        <button onClick={() => this.urlChange(video.name)}>
                                            <div className="row">
                                                {/* <div class="col-md-2 col-sm-2"> */}
                                                    
                                                {/* </div> */}
                                                <div class="col-md-12">
                                                    <img src="../assets/images/play.png" alt=""/><p >{`Aula ${index+1} - Início das Obras`}</p>
                                                </div>
                                            </div>
                                        </button>               
                                    </div>
                                )}
                        </div>
                    </div>    
                </div>
            </div>                


        )
    }
}
