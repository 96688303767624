import React, { Component } from 'react'
import Favoritos from './favoritos'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Base_url, BaseUrlConteudos } from '../common/const'
import Parse from 'html-react-parser';


export default class conteudoInterna extends Component {


    constructor(props) {
        super();
        this.state = { favoritosEndPoint: [], titulo: '', texto: '' }

    }


    componentWillMount() {

        axios.all([
            axios.get(`${Base_url}/posts/${this.props.match.params.id}`),
            axios.get(`${Base_url}/posts-favoritos`)
        ])
            .then(axios.spread((artRes, favRes) => {

                console.log(artRes.data.data)


                this.setState({
                    titulo: artRes.data.data.titulo,
                    texto: artRes.data.data.texto,
                    favoritosEndPoint: favRes.data.data.data
                })
            }));


    }



    render() {

        const { titulo, texto, favoritosEndPoint } = this.state

        return (


            <div className='interna-conteudo'>
                <div className="container-fluid internas">
                    <div className="row titulo-pagina align-items-center ">
                        <div className="col-md-6 descricao">
                            <h1>{titulo}</h1>
                            <p>Por: <span>Ricardo Lombardi</span> </p>
                            <span className='data'>21/10/2019</span>
                        </div>
                    </div>

                    <div className="row conteudo">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 texto-conteudo">
                            <p>{Parse(texto)}</p>
                            <Link to='/conteudo'><button className="button-artigo">VOLTAR</button></Link>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 conteudo-favorito">
                            <span><img src="/../assets/images/star.png" alt="" /></span>
                            <h4>Favoritos do editor</h4>
                            {favoritosEndPoint.map((fav) => <Favoritos slug={fav.url} titulo={fav.titulo} descricao={fav.subtitulo} imagem={BaseUrlConteudos + '/' + fav.imagens[0].name} />)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
