import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { login, menu } from '../login/authActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import If from '../common/operator/if'

class Login extends Component {



    onSubmit(values) {
        const { login } = this.props
        login(values)
       
    }

    componentWillMount() {
        this.props.menu(false)
    }


    render() {
        // console.log(this.props.showMenu)
        const { handleSubmit } = this.props
        if (this.props.user) {
            this.props.history.push("/minhasassinaturas")
        }

        return (
            <div className="login container">
                <div className="row">
                    <div className="col-md-4">
                        <Link to='/'><img src="./assets/images/logo-engenheiros.png" alt="" /></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <hr className="mt-5" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h1>Acesse Sua Conta</h1>
                        <h3>Digite Abaixo Seus Dados De Acesso</h3>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit(v => this.onSubmit(v))}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <Field
                                    className="form-control"
                                    name="email"
                                    component="input"
                                    type="email"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Senha</label>
                                <Field
                                    className="form-control"
                                    name="password"
                                    component="input"
                                    type="password"
                                />
                            </div>

                            <button type="submit" className=" entrar">Entrar</button>
                            <Link to='/cadastro'><button type="submit" className=" cadastro">Quero me cadastrar</button></Link>
                            <Link to='/'><button type="submit" className=" cadastro">Voltar</button></Link>
                            <If condition={this.props.textErro}>
                                <div class="alert alert-danger mt-3" role="alert">
                                    Atenção, {this.props.textErro}
                                </div>
                            </If>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

Login = reduxForm({ form: 'authForm' })(Login)
const mapStateToProps = state => ({ user: state.auth.user, textErro: state.auth.textErro, showMenu: state.auth.showMenu })
const mapDispatchToProps = dispatch => bindActionCreators({ login, menu }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Login)