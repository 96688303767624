import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import TitlePage from '../common/template/titlePage'
import { Base_url, BaseUrlImg, BaseUrlAssinaturas, BaseToken, Authorization_url } from '../common/const'
import Parse from 'html-react-parser'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, Button } from 'react-bootstrap';
import If from '../common/operator/if'
import { limpaTag } from '../common/util'



class assinaturasdetalhes extends Component {

    constructor(props) {
        super();
        this.state = { dados: [],subtitulo:'', img1: '', img2: '', texto1: '', texto2: '', texto3: '', texto4: '', show: false, texto: '', botaoLogin: false }

    }


    componentDidMount() {

        axios.all([
            axios.get(`${Base_url}/assinaturas/${this.props.match.params.id}`)
        ])
            .then(axios.spread((assRes) => {
                this.setState({
                    dados: assRes.data.data,
                    subtitulo: assRes.data.data.texto,
                    img1: assRes.data.data.imagens[1].name,
                    img2: assRes.data.data.imagens[2].name,
                    texto1: assRes.data.data.textos[0].texto,
                    texto2: assRes.data.data.textos[1].texto,
                    texto3: assRes.data.data.textos[2].texto,
                    texto4: assRes.data.data.textos[3].texto
                })
            }));


    }
    

    vinculaAssinatura() {


        if (this.props.user) {
            const values = { "assinante_id": this.props.user.assinante.id, "assinatura_id": this.state.dados.id }
            const config = {
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem('_engenheiro_token')
                }
            }
            console.log(this.props.token)

            axios.post(`${BaseToken}/assinantes/assinaturas`, values, config)
                .then(resp => {

                    this.setState({ show: true, texto: 'Inscrição realizada com sucesso! Acesse o curso na sua area de assinaturas.' })
                })
                .catch(e => console.log(e))
        } else {
            this.setState({ show: true, botaoLogin: true, texto: 'Para acessar o curso você precisa realizar seu cadastro!' })
        }



    }



    render() {
        const { dados, subtitulo ,img1, img2, texto1, texto2, texto3, texto4, show, texto, botaoLogin } = this.state

        var Background = BaseUrlAssinaturas + '/' + img1;
        var Background2 = BaseUrlAssinaturas + '/' + img2;

        return (
            <div className="sobre">
                <div className="container-fluid internas">


                    <div className="row titulo-pagina align-items-center">
                        <div className="col-md-9 descricao">
                            <h1>{dados.titulo}</h1>
                            <span>{Parse(texto1)}</span>
                        </div>

                        <div className="col-sm-3 col-md-3">
                            <img className="float-right" src="../../assets/images/logo_sobre.png" width='80' alt="Logo" />
                        </div>
                    </div>

                    <hr />
                </div>
                <div className="container-fluid mt-5 banner-sobre" style={{ backgroundImage: `url(${Background})` }}></div>


                <div className="container-fluid internas">
                    <div className="row">
                        <div className="col-md-6 desc-esq">
                            <p>{Parse(texto2)}</p>
                        </div>
                        <div className="col-md-6 detalhes">
                            <p>{Parse(texto3)}</p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid internas mt-0">
                    <div className="row align-items-center mt-0">

                        <div className="col-md-6 ric-desc">
                            <p className='mt-2'>{Parse(texto4.replace('https://www.paypalobjects.com/pt_BR/BR/i/btn/btn_buynowCC_LG.gif','../../assets/images/pagamento.png')
                            .replace('formi','form'))}</p>
                            <div className='clearfix'></div>
                            {/* <Link to={`/player/${dados.id}`}><div className='w-100 d-flex justify-content-center'><button>ACESSE AO CURSO</button></div></Link> */}

                            <div className='w-100 d-flex justify-content-center'><button onClick={() => this.vinculaAssinatura()}>QUERO ME INSCREVER</button></div>

                        </div>

                        <div className="col-md-6 ric-img" style={{ backgroundImage: `url(${Background2})` }}></div>
                    </div>

                </div>

                <Modal show={show}>
                    <Modal.Body>{texto}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ show: false })} >
                            Fechar
                        </Button>
                        <If condition={botaoLogin}>
                            <Link to={`/login`}>
                                <Button variant="primary" >
                                    Acesse sua conta
                                </Button>
                            </Link>
                        </If>
                        <If condition={!botaoLogin}>
                            <Link to={`/minhasassinaturas`}>
                                <Button variant="primary" >
                                    Minhas assinaturas
                            </Button>
                            </Link>
                        </If>
                    </Modal.Footer>
                </Modal>


            </div>

        )
    }
}

const mapStateToProps = state => ({ user: state.auth.user, token: state.auth.token })
export default connect(mapStateToProps, null)(assinaturasdetalhes)