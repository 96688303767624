import React from 'react'

export default props => (
    <div>
        <div className="row titulo-pagina align-items-center">
            <div className="col-md-6 descricao">
                <h1>{props.titulo}</h1>
                <span className={` ${props.orange ? 'orange':''}`}>{props.descricao}</span>
            </div>
            
            {props.children}
        </div>
    </div>
)
    
        
