import React from 'react'
import { Switch, Route, Redirect } from 'react-router'


import Home from '../home/home'
import Conteudo from '../conteudo/conteudo'
import Fotos from '../fotos/fotos'
import Sobre from '../sobre/sobre'
import Videos from '../videos/videos'
import Assinaturas from '../assinaturas/assinaturas'
import Arquivos from '../arquivos/arquivos'
import AssinaturasDetalhes from '../assinaturas/assinaturasdetalhes'
import MinhasAssinaturas from '../assinaturas/minhasassinaturas'
import Player from '../assinaturas/player'
import Login from '../login/login'
import Cadastro from '../login/cadastro'
import Ricardo from '../ricardo/ricardo'
import ConteudoInterna from '../conteudo/conteudoInterna'


export default props => (
    <div>
        
            <Switch>
                
                    <Route exact path='/' component={Home} />
                    <Route path='/conteudo' component={Conteudo} />
                    <Route path='/fotos' component={Fotos} />
                    <Route path='/sobre' component={Sobre} />
                    <Route path='/videos' component={Videos} />
                    <Route path='/assinaturas' component={Assinaturas} />
                    <Route path='/arquivos' component={Arquivos} />
                    <Route path='/login' component={Login} />
                    <Route path='/ricardo' component={Ricardo} />
                    <Route path='/artigo/:id/:url' component={ConteudoInterna} />
                    <Route path='/assinaturasdetalhes/:id' component={AssinaturasDetalhes} />
                    <Route path='/minhasAssinaturas' component={MinhasAssinaturas} />
                    <Route path='/player/:id' component={Player} />
                    <Route path='/cadastro' component={Cadastro} />
                    <Redirect from='*' to='/' />
                
            </Switch>
        
    </div>
)