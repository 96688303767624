import React, { Component, lazy } from 'react'
import axios from 'axios'

import {Base_url, BaseUrlVideos} from '../common/const'
import { menu } from '../login/authActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const Carousel = lazy(() => import('../common/carousel/carousel'))
const BlocoInstitucional = lazy(() => import('./blocoInstitucional'))
const BlocoVideo = lazy(() => import('./blocoVideo'))
const BlocoMac = lazy(() => import('./blocoMac'))
const BlocoDepoimentos = lazy(() => import('./depoimentos/blocoDepoimentos'))
const BlocoGaleria = lazy(() => import('./blocoGaleria'))
const BlocoPerguntas = lazy(() => import('./perguntas/blocoPerguntas'))



class home extends Component {


    constructor(){
        super();
        this.state = { video:'' }
    }
  
   
    componentWillMount(){
        this.props.menu(true)
          
         axios(`${Base_url}/videos-principal`, {method: 'GET'})
         .then(response => { 
       
             this.setState({ video: response.data.data[0].itens[0].name })
          }) 
          .catch(function (error) { console.log(error) })
         
    }

    render() {
        const { video } = this.state;
        return (
            <div>
                <Carousel />
                <BlocoInstitucional />
                <BlocoVideo url={BaseUrlVideos+'/'+video}/>
                <BlocoMac />
                <BlocoDepoimentos />
                <BlocoGaleria />
                <BlocoPerguntas />
                
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ menu }, dispatch)
export default connect(null, mapDispatchToProps)(home)