import React, { Component } from 'react'
import axios from 'axios'
import TitlePage from '../common/template/titlePage'
import {Base_url, BaseUrlPdfs} from '../common/const'
import { Link } from 'react-router-dom'
import If from '../common/operator/if'



export default class arquivos extends Component {

    constructor(){
        super()
        this.state = { arquivos:[], pdf:[], titulo:'', voltar: false}
        this.request = this.request.bind(this)
       
    }


    request(pdf = ''){

        const url = pdf ? Base_url+'/pdf-categorias/'+pdf : Base_url+'/pdf-categorias'
        
        this.setState({voltar: pdf ? true : false})
        
        axios(url, {method: 'GET'})
         .then(response => {
                this.setState({  arquivos: response.data.data.children ? response.data.data.children : response.data.data, pdf: response.data.data.pdfs  })
          }) 
          .catch(function (error) { console.log(error) })
    }

    componentWillMount(){
        this.request()
    }




    render() {

        const { arquivos, pdf, voltar } = this.state
        console.log(arquivos)

        return (
            <div className="container-fluid internas assinaturas arquivos">
                <TitlePage titulo={`Arquivos` } descricao=""> </TitlePage>
                
                <div className="row">

                    <If condition={arquivos === ''}>
                         <p>Pasta está vazia!</p>
                    </If>

                   

                    {arquivos && (arquivos.map((arq, index) =>
                        
                        <div className='col-md-2' key={index}>
                            
                                <div className=" cursos" key="index" onClick={(()=>this.request(arq.id))}>
                                    <img src='/assets/images/pasta.jpg' alt=""/>
                                    <p>{arq.nome}</p>
                                </div>
                            
                        </div>
            
                    ))}




                        {pdf && (pdf.map((arq, index) =>
                        
                            <div className='col-md-2' key={index}>
                                <a href={`${BaseUrlPdfs}/${arq.name} `} target = "_blank">
                                    <div className="cursos" key="index">
                                        <img src='/assets/images/download.png' alt=""/>
                                        <p>{arq.titulo}</p>
                                    </div>
                                </a>
                            </div>
                
                        ))}


                    
                 
                    
                </div> 

                <div className='row'>
                    <div className='col-md-2'>
                        {voltar && (
                            <button onClick={(()=>this.request())} type="button" name="" id="" className="btn btn-primary btn-lg btn-block">VOLTAR</button>
                        )}
                    </div>
                </div>
             
                
            </div>
        )
    }
}

