import React, { Component } from 'react'
import 'video-react/dist/video-react.css';
import axios from 'axios'

import {
    Player,
    BigPlayButton,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';
import { Base_url, BaseUrlVideos } from '../common/const'


// const videos = [
//     {
//         path:'http://engenheirosapi.treetec.com.br/uploads/videos/5e23718aeb20a.mp4',
//         titulo:'Lorem Ipsum',
//         descricao:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Assumenda deserunt odit quisquam itaque laboriosam, quasi soluta quam architecto facere vel praesentium expedita at culpa mollitia ab delectus consectetur nobis sunt.',
//         img: '../assets/images/overlay_video.png'
//     },
//     {
//         path:'../assets/images/video_institucional.mp4',
//         titulo:'Lorem Ipsum lorem',
//         descricao:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Assumenda deserunt odit quisquam itaque laboriosam, quasi soluta quam architecto facere vel praesentium expedita at culpa mollitia ab delectus consectetur nobis sunt.',
//         img: '../assets/images/overlay_video.png'
//     },
//     {
//         path:'../assets/images/video_institucional.mp4',
//         titulo:'Lorem Ipsum lorem dolor',
//         descricao:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Assumenda deserunt odit quisquam itaque laboriosam, quasi soluta quam architecto facere vel praesentium expedita at culpa mollitia ab delectus consectetur nobis sunt.',
//         img: '../assets/images/overlay_video.png'
//     }

// ];

export default class itensVideos extends Component {


    constructor(props) {
        super();
        this.state = { videosEndPoint: [] }

    }


    componentWillMount() {

        axios(`${Base_url}/videos`, { method: 'GET' })
            .then(response => {
                // console.log(response.data.data)
                this.setState({ videosEndPoint: response.data.data })
            })
            .catch(function (error) { console.log(error) })

    }

    render() {
        const { videosEndPoint } = this.state

        return (
            <div>
                {videosEndPoint.map((vid, index) =>
                    <div key={index}>

                        {vid.itens.map((video, indexVideo) =>

                            <div className="row itens-videos align-items-center" key={indexVideo}>
                                <div class="col-md-6 p-0">
                                    <Player src={BaseUrlVideos + '/' + video.name}
                                        fluid={false}
                                        height={350}
                                        width={'100%'}
                                        preload={'auto'}
                                    >
                                        <BigPlayButton position="center" />

                                        <ControlBar>
                                            <ReplayControl seconds={10} order={1.1} />
                                            <ForwardControl seconds={10} order={1.2} />
                                            <CurrentTimeDisplay order={4.1} />
                                            <TimeDivider order={4.2} />
                                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                            <VolumeMenuButton />
                                        </ControlBar>
                                    </Player>

                                </div>
                                <div class="col-md-6 p-5">
                                    <h2>{video.titulo}</h2>
                                    <p>{video.sub_titulo}</p>
                                </div>
                            </div>

                        )}
                    </div>
                )}
            </div>
        )
    }
}
