import React, { Component } from 'react'
import TitlePage from '../common/template/titlePage'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import ItensVideos from './itensVideos';






export default class videos extends Component {
    

    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleCurso = this.handleCurso.bind(this);
        this.state = {day: undefined, curso:''};
      }
    
      handleDayChange(selectedDay) {
          const data = formatDate(selectedDay,'YYYY-MM-DD', 'pt-br' );
          this.setState({day: data});
      }

      handleCurso(event) {
        this.setState({curso: event.target.value});
      }
    
    render() {

        const { day, curso } = this.state;
        return (
            <div className="fotos">
                <div className="container-fluid internas">
                    <TitlePage titulo='Vídeos' descricao="Confira algumas aulas demonstrativas">
                        {/*<div className="col-md-4">
                            <form>
                                <div className="form-row">
                                    <div className="col">
                                         <DayPickerInput
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            onDayChange={this.handleDayChange}
                                            format='DD/MM/YYYY'
                                            placeholder={`${formatDate(new Date(),'DD/MM/YYYY', 'pt-br')}`}
                                            dayPickerProps={{
                                            locale: 'pt-br',
                                            localeUtils: MomentLocaleUtils,
                                            }}
                                        /> 
                                    </div>
                                    
                                    <div className="col">
                                        <select  onChange={this.handleCurso} value={curso}>
                                            <option value="0">TODOS OS CURSOS</option>
                                            <option value="1">CURSO 1</option>
                                            <option value="2">CURSO 2</option>
                                            <option value="3">CURSO 3</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                            {day}
                            {curso}
                                        </div>*/}
                    </TitlePage>
                    <hr/>

                    
                    <ItensVideos />                    
                    
                </div>
                    
            </div>
        )
    }
}
