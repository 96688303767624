const url = 'http://api.engenheirosdofuturo.com.br/'

export const urlApi = url + 'api';
export const Base_url = url + 'api/conteudo';
export const BaseToken = url + 'api/token';
export const BaseUrlPostAssinantes = url + 'api/assinantes';
export const BaseUrlImg = url + 'uploads/images/';
export const BaseUrlGaleria = url + 'uploads/galerias';
export const BaseUrlBanners = url + 'uploads/banners';
export const BaseUrlConteudos = url + 'uploads/conteudos';
export const BaseUrlAssinaturas = url + 'uploads/assinaturas';
export const BaseUrlVideos = url + 'uploads/videos';
export const BaseUrlPdfs = url + 'uploads/pdfs';
export const Authorization_url = 'Bearer ' + localStorage.getItem('_engenheiro_token');
export const Application_url = 'treetec';