import React, { Component } from 'react'
import axios from 'axios'
import TitlePage from '../common/template/titlePage'
import {Base_url, BaseUrlAssinaturas} from '../common/const'
import Parse from 'html-react-parser';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { menu } from '../login/authActions'
import { bindActionCreators } from 'redux'
import { RotateCircleLoading } from 'react-loadingg';


class assinaturas extends Component {

    constructor(){
        super()
        this.state = { assinaturas:[], loading:true}
       
    }

    componentWillMount(){
        axios(`${Base_url}/assinaturas`, {method: 'GET'})
         .then(response => {
             this.setState({  assinaturas: response.data.data, loading:false  })
          }) 
          .catch(function (error) { console.log(error) })

          
        
    }


    render() {

        const { assinaturas, loading } = this.state
        console.log(loading)

        return (
            <div className="container-fluid internas assinaturas">
                <TitlePage titulo='Engenharia' descricao="Confira nossas assinaturas e escolha a que melhor se adaptar ao seu perfil. ">

                </TitlePage>
                
                <div className="row">

                <RotateCircleLoading color='#083776' />
            
                    {assinaturas.map((curso, index) =>
                        
                        <div className='col-md-4' key={index}>
                            {/* <Link to={`assinaturasdetalhes/${curso.id}`}> */}
                            <Link to={`/assinaturasdetalhes/${curso.id}`}>
                                <div className=" cursos" key="index">
                                    <img src={BaseUrlAssinaturas+'/'+curso.imagens[0].name} alt=""/>
                                    <p>{Parse(curso.texto)}</p>
                                    <button className="button_curso">INSCREVA-SE</button>
                                </div>
                            </Link>
                        </div>
                    )}
                    
                </div> 
                
            </div>
        )
    }
}


const mapStateToProps = state => ({ user: state.auth.user, token: state.auth.token })
const mapDispatchToProps = dispatch => bindActionCreators({ menu }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(assinaturas)