import React, {Suspense} from 'react'
import { BrowserRouter } from 'react-router-dom'

import Navbar from '../common/template/navbar'
import Footer from '../common/template/footer'
import Routes from './routes'


export default props => (

    <Suspense fallback={ <h1></h1> }>
        <BrowserRouter>
            <div>
                <Navbar/>
                <Routes />
                <Footer/>
            </div>
        </BrowserRouter>
    </Suspense>
)