import React, { Component }  from 'react'
import { Link } from 'react-router-dom'
import If from '../../common/operator/if'
import { connect } from 'react-redux'

class footer extends Component {

    render() {
        
            return (
                <If condition={this.props.showMenu}>
                    <div className="footer-core ">
                        <footer className="container-fluid">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-2">
                                        <h4>Institucional</h4>
                                        <ul>
                                        <Link to='/sobre'><li>Sobre</li></Link>
                                        <Link to='/ricardo'><li>Ricardo Lombardi</li></Link>
                                            {/* <li>Contato</li>
                                            <li>Informações</li> */}
                                        </ul>

                                    </div>
                                    <div className="col-md-2">
                                        <h4>Conteúdo</h4>
                                        <ul>
                                        <Link to='/videos'><li>Videos</li></Link>
                                        <Link to='/conteudo'><li>Artigos/Dicas</li></Link>
                                        <Link to='/fotos'><li>Imagens</li></Link>
                                            {/* <li>Dicas</li> */}
                                        </ul>

                                    </div>
                                    
                                    <div className="col-md-2">
                                        <h4>Assinaturas</h4>
                                        <ul>
                                        <Link to='/assinaturas'><li>Cursos</li></Link>
                                        </ul>

                                    </div>
                                    {/* <div className="col-md-2">
                                        <h4>Central de ajuda</h4>
                                        <ul>
                                            <li>Artigos populares</li>
                                            <li>Atendimento</li>
                                            <li>Soluções de problemas</li>
                                        </ul>
                                        
                                    </div> */}
                                    <div className="col-md-2">
                                        <h4>Mais informações</h4>
                                        <ul>
                                            <li className='phone'>(83)9-9983-8167</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            
                            
                        </footer>
                        <div className="container-fluid footer2">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <span>© 2019 Engenheiros do Futuro - Todos os direitos reservados</span>
                                    </div>
                                    <div className="col-md-2 icons">
                                        <i className="fab fa-facebook-f"></i>
                                        <i className="fab fa-instagram"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </If>
        )
    }
}


const mapStateToProps = state => ({showMenu: state.auth.showMenu })
export default connect(mapStateToProps, null)(footer)


