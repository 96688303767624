import React, { Component } from 'react'
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";
import {BaseUrlGaleria} from '../const'
import { formatDate } from 'react-day-picker/moment';
export default class galeria extends Component {


    constructor(props) {
        super(props);
    
        this.state = {
            imagePopup: '',
          isOpen: false
        };
      }

    render() {
       
       
         const imagens = this.props.request
         const { imagePopup, isOpen } = this.state;
        
        return (
            <div className="row slider"> 

                 {imagens.map(
                    (gl, index)=> 

                    <div id={`slider${gl.id}`} className={`${index === 0 ? 'col-md-6' : 'col-md-3'} p-0 carousel slide carousel-fade`} data-ride="carousel" key={index} data-interval="0">
                        
                        {gl.imagens.map(
                            (img, index_img)=>
                                <div style={{backgroundImage: "url(" + BaseUrlGaleria+'/'+img.name + ")"}}   className={(index_img === 0) ? 'carousel-item active' : 'carousel-item' }  key={index_img} onClick={() => this.setState({ isOpen: true, imagePopup: BaseUrlGaleria+'/'+img.name })}> 
                                </div>
                            )}                       

                        <a className="carousel-control-prev" href={`#slider${gl.id}`} role="button" data-slide="prev">
                            <div className='seta'><span className="carousel-control-prev-icon" aria-hidden="true"></span></div>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href={`#slider${gl.id}`} role="button" data-slide="next">
                            <div className='seta'><span className="carousel-control-next-icon" aria-hidden="true"></span></div>
                            <span className="sr-only">Next</span>
                        </a>

                        <div className="carousel-caption">
                            <h5>{gl.titulo}</h5>
                            <p>{formatDate(gl.data,'DD/MM/YYYY', 'pt-br')}</p>
                        </div>

                    </div>
                    
                )}
        
                {isOpen && (
                    <Lightbox
                        mainSrc={imagePopup}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}


            </div>
                
                    
        )
    }
}
