import React, { Component } from 'react'
import TitlePage from '../common/template/titlePage'
import axios from 'axios'
import {Base_url, BaseUrlImg} from '../common/const'
import Parse from 'html-react-parser';

export default class ricardo extends Component {

    constructor(props){
        super();
        this.state = { ricardoTexto:'', ricardoTitulo:'' ,ricardoImagem:''  }
    }
  
   
    componentWillMount(){
        
          axios(`${Base_url}/institucional/8?grupo=sobre`, {method: 'GET'})
          .then(response => {
              this.setState({ ricardoTexto: response.data.data.texto,
                              ricardoImagem: response.data.data.imagem_name, 
                              ricardoTitulo: response.data.data.titulo 
                            })
           }) 
           .catch(function (error) { console.log(error) })
         
    }

    render() {
        const { ricardoTexto, ricardoTitulo, ricardoImagem } = this.state

        var Background = BaseUrlImg+'/'+ricardoImagem;
        return (
            <div className='ricardo'>
                <div className="container-fluid internas ">
                    <TitlePage titulo={ricardoTitulo} descricao="Fundador e idealizador no projeto" orange={true}>
                        <div className="col-sm-3 col-md-6">
                            <img className="float-right" src="./assets/images/dec-ricardo.png" width='100' alt="decorativo ricardo"/>
                        </div>
                    </TitlePage>

                
                    <div className="row">
                        <div class="col-md-6 bg-ric" style={{backgroundImage: `url(${Background})`}}></div>
                        <div class="col-md-6 conteudo">
                            {Parse(ricardoTexto)}
                        </div>
                    </div>
                </div>
        </div>
        )
    }
}
