import React, { Component,lazy } from 'react'
import axios from 'axios'


import TitlePage from '../common/template/titlePage'
import Favoritos from './favoritos'
import {Base_url, BaseUrlConteudos} from '../common/const'

const Artigos = lazy(() => import('./artigos'))

export default class conteudo extends Component {


    constructor(props){
        super();
        this.state = { artigosEndPoint:[], 
                       favoritosEndPoint:[],
                       page: 1
                     }
        this.verMais = this.verMais.bind(this);
    }
  
   
    componentWillMount(){
        
           axios.all([
            axios.get(`${Base_url}/posts`),
            axios.get(`${Base_url}/posts-favoritos`)
          ])
          .then(axios.spread((artRes, favRes) => {
                this.setState({ artigosEndPoint: artRes.data.data.data, favoritosEndPoint: favRes.data.data.data })
          }));
         
    }

    verMais(){

        let qdt = this.state.page + 1;
        console.log(qdt)
        axios.all([
            axios.get(`${Base_url}/posts?page=${qdt}`),
        ])
          .then(axios.spread((artRes) => {
                this.setState({ artigosEndPoint:  [...this.state.artigosEndPoint, ...artRes.data.data.data] })
          }));

          this.setState( {page: qdt})
    
    }

    render() {

        
        const { artigosEndPoint, favoritosEndPoint } = this.state


        return (
            <div className="container-fluid internas">
                <TitlePage titulo='Artigos' descricao="As melhores dicas para seu crescimento profissional"></TitlePage>

              
                <div className="row conteudo">
                    
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                        <hr/>
                        <h3>Últimos</h3>  
                        {artigosEndPoint.map((art, index)=><div key={index}><Artigos slug={art.url}  id={art.id} titulo={art.titulo} categoria={art.categoria} descricao={art.subtitulo} imagem={BaseUrlConteudos+'/'+art.imagens[0].name} chave={index}/></div>)}
                        <div className='clearfix'></div>
                        <button className="button-artigo" onClick={this.verMais}>VER MAIS</button>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 conteudo-favorito">
                        <span><img src="./assets/images/star.png" alt=""/></span>
                        <h4>Favoritos do editor</h4>  
                        {favoritosEndPoint.map((fav)=><Favoritos slug={fav.url} titulo={fav.titulo} id={fav.id}  descricao={fav.subtitulo} imagem={BaseUrlConteudos+'/'+fav.imagens[0].name} />)}
                    </div>
                </div>
                
            </div>
        )
    }
}



